<template>
  <div class="case-conent">
    <div class="hd">学历案日志</div>
    <!--学历案日志列表-->
    <div class="table-content">
      <div class="table">
        <el-table
            border
            :header-cell-style="{background:'#D1EEFE',color:'#333'}"
            :data="journalist"
        >
          <el-table-column prop="sort" label="序号"  header-align="center" width="80"></el-table-column>
          <el-table-column prop="" label="图书名称"  header-align="center">
            <template #default="scope">
              <span class="colourBox">{{scope.row.bookName}}</span>
            </template>
          </el-table-column>
          <el-table-column prop="resourceName" label="课时名称" header-align="center"/>


          <el-table-column prop="" label="操作" header-align="center" width="280">
            <template #default="scope">
              <el-button @click="tosDetails(scope)" type="primary" size="mini" plain>看学历案</el-button>
              <el-button @click="toTimeline(scope)" type="primary" size="mini" plain>看学习时间线</el-button>
            </template>
          </el-table-column>

        </el-table>
      </div>
      <div class="page">
        <div class="pag-box" >
          <div class="total-box">
            <span class="total_pag">共<em> {{ total }} </em>条</span>
          </div>
          <el-pagination
              :current-page="currentPage"
              background
              :page-size="pageSize"
              layout="prev, pager, next"
              :total="total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              v-if="total != 0"
          >
            <span class="pag_sise">每页显示</span>
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 充值订单详情弹框-->
    <el-dialog :visible.sync="rechargeDialog" :close-on-click-modal="false" title="充值订单详情" width="800px" left>
      <div class="dialogOrder">
        <div class="dialogBody">
          <div class="lf">商品名称：</div>
          <div class="rg">学历案充值</div>
        </div>
        <div class="dialogBody">
          <div class="lf">订单号：</div>
          <div class="rg">{{d1.orderNumber}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">学生姓名：</div>
          <div class="rg">{{d1.stuName}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值类型：</div>
          <div class="rg">{{d1.rechargeType}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">原价：</div>
          <div class="rg">{{d1.originalPrice}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值金额：</div>
          <div class="rg">{{d1.rechargeAmount}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">折扣：</div>
          <div class="rg">{{d1.discount}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值方式：</div>
          <div class="rg">{{d1.rechargeMode}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">状态：</div>
          <div class="rg">
            <span v-if="d1.state==1">已完成</span>
            <span v-if="d1.state==2">未完成</span>
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">充值时间：</div>
          <div class="rg">
            {{d1.time}}
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">商户名称：</div>
          <div class="rg">
            {{d1.time}}
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">交易单号：</div>
          <div class="rg">
            {{d1.time}}
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">商户单号：</div>
          <div class="rg">
            {{d1.time}}
          </div>
        </div>
      </div>
      <div class="dialogFooter">
        <el-button @click="closRechargeObj()" round style="border: 1px solid #1A86FF; background-color: #1A86FF; color: #fff" type="primary">确定</el-button>
      </div>
    </el-dialog>
    <!-- 购买订单详情弹框-->
    <el-dialog :visible.sync="buyDialog" :close-on-click-modal="false" title="购买订单详情" width="800px" left>
      <div class="dialogOrder">
        <div class="dialogBody">
          <div class="lf">购买图书：</div>
          <div class="rg">{{d2.bookName}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">购买章节：</div>
          <div class="rg">{{d1.orderNumber}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">订单号：</div>
          <div class="rg">{{d1.stuName}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">学生姓名：</div>
          <div class="rg">{{d1.rechargeType}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">购买类型：</div>
          <div class="rg">{{d1.originalPrice}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">购买金额：</div>
          <div class="rg">{{d1.rechargeAmount}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">学币抵扣：</div>
          <div class="rg">{{d1.discount}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">实付金额：</div>
          <div class="rg">{{d1.rechargeMode}}</div>
        </div>
        <div class="dialogBody">
          <div class="lf">购买方式：</div>
          <div class="rg">
            <span v-if="d1.state==1">已完成</span>
            <span v-if="d1.state==2">未完成</span>
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">状态：</div>
          <div class="rg">
            <span v-if="d1.state==1">已完成</span>
            <span v-if="d1.state==2">未完成</span>
          </div>
        </div>

        <div class="dialogBody">
          <div class="lf">购买时间：</div>
          <div class="rg">
            {{d1.time}}
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">商户名称：</div>
          <div class="rg">
            {{d1.time}}
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">交易单号：</div>
          <div class="rg">
            {{d1.time}}
          </div>
        </div>
        <div class="dialogBody">
          <div class="lf">商户单号：</div>
          <div class="rg">
            {{d1.time}}
          </div>
        </div>
      </div>
      <div class="dialogFooter">
        <el-button @click="closbuyObj()" round style="border: 1px solid #1A86FF; background-color: #1A86FF; color: #fff" type="primary">确定</el-button>
      </div>
    </el-dialog>
  </div>

</template>


<script>
import {qureyJournalObj} from '@/api/journal'   //路径
export default {
  name: '',
  data() {
    return {
      pageSize:null,
      pageCurrent:null,
      total:null,
      //listData:null,
      journalist:[],
      currentPage:null,

      clickFlag:1,

      d1:[],
      d2:[],
      curA:false,

      rechargeDialog:false,
      buylistData:[
        {
          sort:1,
          subject:'语文',
          bookName:'GR202109300001',
          chapter:'第一单元 一 《合欢树》',
          buyType:1,
          purchaseAmount:2356,
          purchaseMethod:1,
          state:1,
          time:'2021-10-12 15:38:45'
        }
      ],
      buyDialog:false,
    }
  },
  created() {
    this.EducationCase = this.$route.query.EducationCase;
    this.clickFlag = this.$route.query.clickFlag;
    this.stu = this.$route.query.stu;
    this.listObj()
  },
  watch: {
    // 监视搜索词变化
    $route(){
      this.clickFlag= this.$route.query.clickFlag
    },

  },
  methods: {
    //充值详情弹框
    rechargeObj(scope) {
      this.d1= scope.row
      this.rechargeDialog=true
    },
    closRechargeObj() {
      this.rechargeDialog=false
    },
    //购买详情弹框
    buyObj(scope) {
      this.d2= scope.row
      this.buyDialog=true
    },
    closbuyObj() {
      this.buyDialog=false
    },


    //充值订单列表
    listObj() {
      let params = {
        pageCurrent: this.currentPage,//初始页
        pageSize: this.pageSize,//每页的数据
      }
      qureyJournalObj(params).then((res) => { // eslint-disable-line no-unused-vars
        //this.ListData = res.data.data.records
        this.journalist= res.data.data.records
        this.pageSize = res.data.data.size;
        this.currentPage = res.data.data.current;
        this.total = res.data.data.total
      })
    },
    //充值订单列表分页
    handleSizeChange(val) {
      this.pagesize = val;
      this.listObj();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      // this.setContextData("currentPage8", this.currentPage);
      // 刷新页面内容
      this.listObj();
    },
    // 去详情
    tosDetails(scope) {
      this.$router.push({
        path: "/mainb/details",
        query: {
          EducationCase: this.EducationCase,
          clickFlag: 0,
          stu:this.stu,
          bookId: scope.row.bookId,
          folderId: scope.row.folderId,
          id: scope.row.id,
          type:1,
          journal:1
        }
      });
    },
    // 去看学习时间线
    toTimeline(scope) {
      this.$router.push({
        path: "/mainb/timeline",
        query: {
          EducationCase: this.EducationCase,
          clickFlag: this.clickFlag,
          resourceName: scope.row.resourceName,
          stu:this.stu,
          bookId:scope.row.bookId,
          folderId:scope.row.folderId,
          id:scope.row.id,
          type:scope.row.type,
        }
      });
    },

  },
  components: {

  }
}
</script>
<style lang="scss" scoped>
.case-conent {
  margin: 20px auto;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  opacity: 0.9;

  .hd {
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    font-weight: bold;
    background: #37AEFF;
    text-align: center;
    color: #fff;
    opacity: 1;
  }

  .table-content {
    width: 96%;
    margin: 10px auto;
    .search {
      width: 100%;
      height: 40px;
      display: flex;
      margin-bottom: 10px;
      justify-content: flex-end;
      .fromItem {
        margin-left: 10px;

      }
    }
    .table {
      height: 60%;
    }
    .page {
      height: 40px;
    }
  }

  .pag-box {
    width: 100%;
    padding-top: 10px;
    box-sizing: content-box;
    justify-content: center;
    display: flex;

    .total-box {
      float: left;
      line-height: 32px;
      margin-right: 10px;
      justify-content: flex-end;
      display: flex;

      .el-pagination {
        white-space: nowrap;
        padding: 2px 5px 2px 0px;
        color: #303133;
        font-weight: 700;

        .el-pager li {
          background: #ccc;
          -webkit-box-sizing: border-box;
        }

        .el-pager li .active {
          background-color: #FF6820;
          color: #FFF;
        }
      }


    }

  }
}
.dialogOrder {
  width: 100%;
  margin: auto auto 20px auto;
  .dialogBody {
    width: 100%;
    line-height: 30px;
    display: flex;
    .lf {
      width: 50%;
      color: #666;
      text-align: right;
    }
    .rg {
      width: 50%;
      color: #000;
      text-align: left;
    }
  }
}
.dialogFooter {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #f2f2f2;
  display: flex;
  justify-content: center;
}
</style>
